import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { styled, alpha } from '@mui/material/styles';
import { Button, Container, List, ListItem, ListItemText, Paper,Typography, Autocomplete, Box, TextField, Pagination } from '@mui/material';
import BasicTabs from '../../components/tabs/Tabs1';
import { getnews, getAdminStats, upnews, getcategories, getcity } from '../../slices/news';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import newsService from '../../services/news.service';
import MediaCard from '../../components/card/Card';
import { setStatus } from '../../slices/Status';

import dayjs from 'dayjs';
import Loader from '../../components/loader/Loader';

const Search = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 100,
  right: 150,
  borderRadius: theme.shape.borderRadius,
  border: '1px solid #000',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: '#000',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1.5, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '25ch',
    },
  },
}));

const News = ({ setLoader }) => {
  const [results, setResults] = useState([]);

  const [page, setPage] = useState(1);
  const [newstype, setNewstype] = useState("short");
  const status = useSelector((state) => state.status);
  const [query, setQuery] = useState();
  const [search, setsearch] = useState();
  const { categories: catData } = useSelector((state) => state.news);
  const [category, setCategory] = useState(null);
  const [city, setCity] = useState(null);  
  const [cityData, setCityData] = useState(null);  

  const [bottomLoader,setbottomLoader]=useState(false)
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [filteredNews, setFilteredNews] = useState(null);
  const [selectedNews, setSelectedNews] = useState(null);
  const [openPaper, setOpenPaper] = useState(false);
  const [focused, setFocused] = useState(false);  

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [newsSourceName, setNewsSourceName] = useState('');
  const [stats, setstats] = useState();
  const navigate = useNavigate();

  // console.log('filteredNews:', filteredNews);
  // console.log('Start Date:', startDate);
  // console.log('End Date:', endDate);

  // console.log("results:",results);
  const paperRef = useRef(null); // Create a ref for the Paper component

  // Function to handle outside click
  const handleClickOutside = (event) => {
    if (paperRef.current && !paperRef.current.contains(event.target)) {
      setOpenPaper(false); // Close the Paper
    }
  };

  useEffect(() => {
    // Add event listener for clicks
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  // const date = new Date(newsData?.created_at);
  // const formattedDate = date.toLocaleDateString('en-GB', {
  //   day: '2-digit',
  //   month: 'short',
  //   year: 'numeric'
  // });
  

  // useEffect(() => {
  //   setLoader(true);
  //   const params = {
  //     status: status,
  //     news_type: newstype,
  //     page: page,
  //     limit: 12,
  //     query: query,
  //   };
  //     dispatch(getcategories(params))
  //     dispatch(getcity())
  //     dispatch(getnews(params))
  //       .then((data) => {
  //         // console.log("Data:",data);
  //         const updata = data?.payload?.data?.data?.data;
  //         if (page === 1) {
  //         setResults(updata);
  //         } else {
  //           setResults((prevResults) => [...prevResults,...updata])
  //         }
  //         setHasMore(updata.length < 12)
  //         setHasMore(false);
  //         setLoader(false);
  //       })
  //       .catch(() => {
  //         setLoader(false);
  //       });
  //   console.log("API Request Params:", params);
  // },  [newstype, query, page, dispatch, status, setLoader]);

  // useEffect(()=>{
  //    dispatch(getcategories());
  //    dispatch(getcity());
    
  // },[])

  // useEffect(() => {
  //   const params = {
  //     status: status,
  //     news_type: newstype,
  //     page: page,
  //     limit: 12,
  //     query: query,
  //   };
  
  //   const fetchData = async () => {
  //     try {
    
  
  //       const data = await dispatch(getnews(params));
  //       console.log("Data:",data);
        
  //       const fetchedNews = data?.payload?.data?.data?.data;
  //       console.log("fetchedNews:",fetchedNews);
        
  
  //       if (fetchedNews.length < 12) {
  //         setHasMore(false); 
  //       } else {
  //         setHasMore(true);
  //       }
  //       // Handle the news results for page 1 or subsequent pages
  //       if (page === 1) {
  //         setResults(fetchedNews);
  //       } else {
  //         setResults((prevResults) => {
  //           return [...prevResults, ...fetchedNews];
  //         });
  //       }
  //       // setHasMore(fetchedNews.length > 0);
  //       // setLoader(false);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     } finally {
  //     }
  //   };
  
  //   fetchData();
  //   console.log("API Request Params:", params);
  
  // }, [newstype, query, page]);
  



    useEffect(() => {
      setLoader(true)
      dispatch(getcity())
      .then((data) => {
        const updata = data.payload.data.data.data
        // console.log("Data:",updata);
        setCityData(updata);
      })
      .catch(() => {
        setLoader(false);
      })  
      dispatch(getAdminStats())
      .then((data) => {
        const updata = data.payload.data.data
        // console.log("Data:",updata);
        setstats(updata);
      })
      .catch(() => {
        setLoader(false);
      })  
      setLoader(false);

    },[])


//   useEffect(() => {
//     const StatusParams = searchParams.get('status');
//     console.log("StatusParams:",StatusParams);
    
//     switch (StatusParams) {
//         case "approved":
//             dispatch(setStatus("approved"));
//             break;
//         case "rejected":
//             dispatch(setStatus("rejected"));
//             break;
//         case "pending":
//             dispatch(setStatus("pending"));
//             break;
//         default:
//             dispatch(setStatus("pending"));
//     }
// }, [dispatch, searchParams]);
const fetchData = async (params) => {
  try {
    setbottomLoader(true)
    // Fetch the news data
    const data = await dispatch(getnews(params));
    // console.log("Data:", data);

    const fetchedNews = data?.payload?.data?.data?.data || [];
    // console.log("fetchedNews:", fetchedNews);

    // Check for more data
    // setHasMore(fetchedNews.length >= 12);

    // Filter the fetched news data
    // const filtered = fetchedNews.filter((news) => {
    //   console.log("news:", news);

    //   if (!news) return false;

    //   const newsDate = dayjs(news?.created_at);
    //   const matchesStartDate = !startDate || newsDate.isSame(startDate, 'day') || newsDate.isAfter(startDate, 'day');
    //   const matchesEndDate = !endDate || newsDate.isSame(endDate, 'day') || newsDate.isBefore(endDate, 'day');

    //   const matchesCategory = !category || news.category?.id === category?.id;
    //   const matchesCity = !city || news.city?.id === city?.id;
    //   const matchesQuery = query ? new RegExp(`^${query}`, 'i').test(news?.content?.en?.meta_title) && (!category || news.category?.id === category?.id): !category || news.category?.id === category?.id;
    //   const matchesType = newstype ? news.news_type === newstype : true;
    //   const matchesNewsSourceName = !newsSourceName || news.news_source?.name?.toLowerCase().includes(newsSourceName.toLowerCase());

    //   return matchesCategory && matchesCity && matchesQuery && matchesType && matchesStartDate && matchesEndDate && matchesNewsSourceName;
    // });

    // Update filtered news state
    setFilteredNews(fetchedNews);

    // Handle pagination: if it's the first page, set results directly; otherwise, append more data
    if (page === 1) {
      setResults(fetchedNews);
    } else {
      setResults((prevResults) => [...prevResults, ...fetchedNews]);
    }

  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setbottomLoader(false)
  }
};

useEffect(() => {
  const params = {
    status: status,
    news_type: newstype,
    page: page,
    news_source:newsSourceName,
    limit: 12,
    query
  };

  console.log( category, city,newsSourceName,query)
  

  fetchData(params);

}, [newstype, query, page, status, category, city, startDate, endDate, newsSourceName]);



const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });
};

  const updateNews = (event, value) => {
    setPage(value);
  };
  

  

  const handleInputChange = (e) => {
    setPage(1)
    const newQuery = e.target.value;
    setOpenPaper(true)
    setsearch(newQuery);
    setQuery(newQuery);

};


 const handleTabChange = (newTabType) => {
  console.log("Tab changed to:", newTabType);
  setNewstype(newTabType);
};

useEffect(() => {
  // Reset the page to 1 whenever newstype changes
  setPage(1);
}, [newstype,status,query]); 

  

  // const handleItemClick = (result) => {
  //   setSelectedNews(result);
  //   setOpenPaper(false);
  //   setQuery(result?.content?.en?.meta_title);
  //   setFocused(false);
  // };

  const handleFocus = () => {
    setFocused(true);
    setOpenPaper(true)
  };

  const handleScroll = (e) => {
    const totalItems = stats ? stats[newstype][status] : 0;
    // console.log("totalItems:",totalItems)
    const totalPages = Math.ceil(totalItems / 12);
    // console.log("totalPages:",totalPages)
    // console.log("page:",page)

    const bottomReached = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10;
  
    // Check if the user has scrolled close to the bottom and if there are more items
    if (bottomReached ) {
      if (page < totalPages && filteredNews?.length !== 0) {
        setPage((prevPage) => prevPage + 1);
      } 
    }
  };
  
  
  
  


  return (
    <Container className="content" maxWidth="fluid"  onScroll={handleScroll} style={{overflowY:'auto',maxHeight:'87vh',position:'relative'}}>

      {/* Pass filteredNews to BasicTabs */}
      <BasicTabs 
        setQuery={setQuery}
        setPage={setPage}
        setLoader={setLoader} 
        status={status} 
        setNewstype={handleTabChange} 
        newsData={results}
        catData={catData}
        category={category}
        setCategory={setCategory}
        cityData={cityData}
        city={city}
        setCity={setCity}  
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        newstype={newstype}
        newsSourceName={newsSourceName}
        setNewsSourceName={setNewsSourceName}
        bottomLoader={bottomLoader}
      />
     

      <Link to="/news/addnews">
        <Button sx={{ position: 'absolute', top: 70, right: 30,  }} variant="contained">
          New News
        </Button>
      </Link>
   

      {/* Search Input */}
      <Search sx={{ position: 'absolute', top: 70, right: 160 }}>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search…"
          value={search}
          onChange={handleInputChange}
          onFocus={handleFocus} 
          inputProps={{ 'aria-label': 'search' }}
        />
      </Search>
      
      {openPaper && results.length > 0 &&(
  <Paper
  ref={paperRef} 
  onScroll={handleScroll}
    sx={{
      position: 'absolute',
      top: 160,
      right: 150,
      width: '25%',
      maxHeight: '400px', 
      overflowY: 'auto', 
      borderRadius: 2, 
      boxShadow: 3,
      padding: 2, 
      backgroundColor: '#fff',
      zIndex:100
    }}
  >
    <List>
      {results.map((result) => (
        <ListItem 
          key={result.id}
          onClick={() => {
            navigate(`/news/editnews/${result.id}`)
          }}
          sx={{
            borderBottom: '1px solid #e0e0e0',
            '&:last-child': { borderBottom: 'none' }, 
            '&:hover': {
              backgroundColor: '#f5f5f5', 
            },
          }}
        >   
          <ListItemText
            primary={
              <Typography variant="subtitle1" fontWeight="bold" color="primary"  sx={{ fontSize: '14px' }} >
                {result?.content?.en?.meta_title}
              </Typography>
            }
            // secondary={
            //   <Typography variant="body2" color="textSecondary">
            //     {result?.meta_description}
            //   </Typography>
            // }
          />
        </ListItem>
      ))}
    </List>
  </Paper>
)}
    </Container>
  );
};

export default News;
