import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import EventBus from "../utils/EventBus";

import newsService from "../services/news.service";

export const createnews = createAsyncThunk(
  "newsit/createnews",
  async (formData, thunkAPI) => {
    try {
      const data = await newsService.createNews(formData);
      return { data: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createnewsstories = createAsyncThunk(
  "newsit/createnewsstories",
  async (formData, thunkAPI) => {
    try {
      const data = await newsService.createNewsStories(formData);
      return { data: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

//searchnewsstories
export const searchnewsstories = createAsyncThunk(
  "newsit/searchnewsstories",
  async (formData, thunkAPI) => {
    try {
      const data = await newsService.searchNewsStories(formData);
      return { data: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const uploadimage = createAsyncThunk(
  "newsit/uploadimage",
  async ({news_id, formVata}, thunkAPI) => {
    try {
      const data = await newsService.uploadImage(news_id, formVata);
      return { data: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

// Delete News image
export const deleteNewsImage = createAsyncThunk(
  "newsit/deleteNewsImage",
  async ({news_id}, thunkAPI) => {
    try {
      const data = await newsService.deleteNewsImage(news_id);
      return { data: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
//uploadTopicImage
export const uploadTopicImage = createAsyncThunk(
  "newsit/uploadTopicImage",
  async ({topic_id, formData}, thunkAPI) => {
     console.log(formData);
    try {
      const data = await newsService.uploadTopicImage(topic_id, formData);
      return { data: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

//UploadStoryImage
export const uploadstoryimage = createAsyncThunk(
  "newsit/uploadstoryimage",
  async ({ news_id, formVata }, thunkAPI) => {
    //console.log(formVata);
    try {
      const data = await newsService.uploadStoryImage(news_id, formVata);
      return { data: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);  

export const getnews = createAsyncThunk(
  "newsit/getnews",
  async (page, thunkAPI) => {
    try {
      const data = await newsService.getNews(page);
      return { data: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const newsTranslate = createAsyncThunk(
  "newsit/newsTranslate",
  async ({ news_id, payload }, thunkAPI) => {
    try {
      const data = await newsService.newsTranslate(news_id, payload);
      console.log('Translation response:', data);
      return  data ;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);



export const getstorynews = createAsyncThunk(
  "newsit/getstorynews",
  async (page, thunkAPI) => {
    try {
      const data = await newsService.getStoryNews(page);
      return { data: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const upnews = createAsyncThunk(
  "newsit/upnews",
  async (currentPage, thunkAPI) => {
    try {
      const data = await newsService.getNews(currentPage);
      return { data: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getnewsbyid = createAsyncThunk(
  "api/getnewsbyid",
  async (news_id, thunkAPI) => {
    try {
      const data = await newsService.getNewsbyid(news_id);
      return { newsdata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        //console.log(error.response.status);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getstoriesbyid = createAsyncThunk(
  "api/getstoriesbyid",
  async (news_id, thunkAPI) => {
    try {
      const data = await newsService.getStoriesbyid(news_id);
      return { newsdata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        //console.log(error.response.status);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updatenews = createAsyncThunk(
  "api/updatenews",
  async ({news_id, formData}, thunkAPI) => {
    try {
      const data = await newsService.updateNews(news_id, formData);
      return { newsdata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        //console.log(error.response.status);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

//updateStories
export const updatestories = createAsyncThunk(
  "api/updatestories",
  async ({id, formData}, thunkAPI) => {
    try {
      const data = await newsService.updateStories(id, formData);
      return { newsdata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        //console.log(error.response.status);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateaction = createAsyncThunk(
  "api/updateaction",
  async ({news_id, val}, thunkAPI) => {
    try {
      const data = await newsService.updateActions(news_id, val);
      return { newsdata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        //console.log(error.response.status);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updatepublish = createAsyncThunk(
  "api/updatepublish",
  async ({news_id}, thunkAPI) => {
    try {
      const data = await newsService.updatePublish(news_id);
      return { newsdata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        //console.log(error.response.status);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deletenews = createAsyncThunk(
  "api/deletenews",
  async ({news_id}, thunkAPI) => {
    try {
      const data = await newsService.deleteNews(news_id);
      return { newsdata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        //console.log(error.response.status);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getnewsstories = createAsyncThunk(
  "newsit/getnewsstories",
  async (formData, thunkAPI) => {
    try {
      const data = await newsService.getNewsStories(formData);
      return { data: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getcategories = createAsyncThunk(
  "newsit/getcategories",
  async (formData, thunkAPI) => {
    try {
      const data = await newsService.getCategories(formData);
      return { data: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
//getTopics
export const gettopics = createAsyncThunk(
  "newsit/gettopics",
  async (formData, thunkAPI) => {
    try {
      const data = await newsService.getTopics(formData);
      return { data: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

//addTopics
export const addTopics = createAsyncThunk(
  "newsit/addTopics",
  async (formData, thunkAPI) => {
    try {
      const data = await newsService.addTopics(formData);
      return { data: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

//DeleteTopics

export const deleteTopics = createAsyncThunk(
  "api/deleteTopics",
  async ({topic_id}, thunkAPI) => {
    try {
      const data = await newsService.deleteTopics(topic_id);
      return { data: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        //console.log(error.response.status);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

//UpadteTopics
export const updateTopics = createAsyncThunk(
  "api/updateTopics",
  async ({topic_id, formData}, thunkAPI) => {
    try {
      console.log("Thunk Received topic_id:", topic_id);
      console.log("Thunk Received formData:", formData);
      const data = await newsService.updateTopics(topic_id, formData);
      return { data: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        //console.log(error.response.status);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getnewsad = createAsyncThunk(
  "newsit/getnewsad",
  async (formData, thunkAPI) => {
    try {
      const data = await newsService.getNewsad(formData);
      return { data: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

//cardendpoint
export const cardendpoint = createAsyncThunk(
  "newsit/cardendpoint",
  async (news_id, thunkAPI) => {
    try {
      const data = await newsService.cardEndpoint(news_id);
      return { data: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        //console.log(error.response.status);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);


// getComments
export const getcomments = createAsyncThunk(
  "newsit/getcomments",
  async (formData, thunkAPI) => {
    try {
      const data = await newsService.getComments(formData);
      return { data: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        //console.log(error.response.status);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

//DeleteComments
export const deletecomments = createAsyncThunk(
  "newsit/deletecomments",
  async ({comment_id}, thunkAPI) => {
    try {
      const data = await newsService.deleteComments(comment_id);
      return { data: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.error(error.response?.status);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

//Deletestories
export const deletestories = createAsyncThunk(
  "newsit/deletestories",
  async ({stories_id}, thunkAPI) => {
    try {
      const data = await newsService.deleteStories(stories_id);
      return { data: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.error(error.response?.status);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getcity = createAsyncThunk(
  "newsit/getcity",
  async (thunkAPI) => {
    try {
      const data = await newsService.getCity();
      return { data: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.error(error.response?.status);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getAdminStats = createAsyncThunk(
  "newsit/getAdminStates",
  async (thunkAPI) => {
    try {
      const data = await newsService.getAdminStates();
      return { data: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getstates = createAsyncThunk(
  "newsit/getstates",
  async (thunkAPI) => {
    try {
      const data = await newsService.getStates();
      return { data: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

//CardPreview
export const CardPreview = createAsyncThunk(
  "newsit/CardPreview",
  async ({ news_id, language },thunkAPI) => {
    try {
      const data = await newsService.CardPreview(news_id,language);
      return { data: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

const initialState ={
    news: [],
    storyNews: [],
    newsStories: [],
    categories:[],
    topics:[],
    newsAd:[],
    cities:[],
    states:[],
    comments: [],
    error: null,
    innews: null,
    instory: null,
    message: null
}

const newsSlice = createSlice({
  name: "newsit",
  initialState,
  extraReducers: {
    [createnews.fulfilled]: (state, action) => {
      //console.log(action.payload.data);
      state.news.push(action.payload.data.data.data);
      return state;
    },
    [createnews.rejected]: (state, action) => {
      state.message = action.payload.data.message;
      return state;
    },
    [createnewsstories.fulfilled]: (state, action) => {
      state.newsStories.push(action.payload.data.data.data);
      return state;
    },
    [createnewsstories.rejected]: (state, action) => {
      state.message = action.payload.data.message;
      return state;
    },
    //searchnews Stories
    [searchnewsstories.fulfilled]: (state, action) => {
      state.newsStories.push(action?.payload?.data?.data);
      return state;
    },
    [searchnewsstories.rejected]: (state, action) => {
      state.message = action.payload.data.message;
      return state;
    },
    [deleteNewsImage.fulfilled]: (state) => {
      return state;
    },
    // Delete New Image
    [uploadTopicImage.fulfilled]: (state) => {
      return state;
    },
    //uploadTopicImage
    [uploadTopicImage.fulfilled]: (state) => {
      return state;
    },
    //uploadStory Image
    [uploadstoryimage.fulfilled]: (state) => {
      return state;
    },
    [getnews.fulfilled]: (state, action) => {
      state.news = action.payload.data.data.data
      return state;
    },
    [getnews.rejected]: (state, action) => {
      state.message = action.payload.data.message;
      return state;
    },
    [newsTranslate.fulfilled]: (state, action) => {
      state.news = action.payload.data
      return state;
    },
    [newsTranslate.rejected]: (state, action) => {
      state.message = action.payload.data.message;
      return state;
    },
    [getstorynews.fulfilled]: (state, action) => {
      state.storyNews = action.payload.data.data.data
      return state;
    },
    [updateaction.fulfilled]: (state, action) => {
      return state;
    },
    [updateaction.rejected]: (state, action) => {
      state.message = action.payload.data.message;
      return state;
    },
  [updatepublish.fulfilled]: (state, action) => {
    return state;
  },
    [upnews.fulfilled]: (state, action) => {
    state.news.push(...action.payload.data.data.data);
    return state;
  },
  [upnews.rejected]: (state, action) => {
    state.message = action.payload.data.message;
    return state;
  },
  [getnewsbyid.fulfilled]: (state, action) => {
    state.innews = action.payload.newsdata.data
    return state;
  },
  [getstoriesbyid.fulfilled]: (state, action) => {
    state.instory = action.payload.newsdata.data
    return state;
  },
  [updatenews.fulfilled]: (state, action) => {
    const {
      arg: { news_id },
    } = action.meta;
    if (news_id) {
      state.news = state.news.map((item) =>
        item.id === news_id ? action.payload.newsdata.data : item
      );
    };
  },

// updateStories
[updatestories.fulfilled]: (state, action) => {
  const {
    arg: { id },
  } = action.meta;

  if (id ) {
    state.newsStories = state.newsStories.map((item) =>
      item.id === id ? action.payload.newsdata.data : item
    );
  }
},
  [deletenews.fulfilled]: (state, action) => {
    const {
      arg: { news_id },
    } = action.meta;
    if (news_id) {
      state.news = state.news.filter((item) => item.id !== news_id);
    };
  },
  [getnewsstories.fulfilled]: (state, action) => {
    state.newsStories = action.payload.data.data.data
    return state;
  },
  [getnewsstories.rejected]: (state, action) => {
    state.message = action.payload.data.message;
    return state;
  },
  [getcategories.fulfilled]: (state, action) => {
    state.categories = action.payload.data.data.data
    return state;
  },
  [getcategories.rejected]: (state, action) => {
    state.message = action.payload.data.message;
    return state;
  },
  [gettopics.fulfilled]: (state, action) => {
    state.topics = action.payload.data.data.data
    return state;
  },
  [gettopics.rejected]: (state, action) => {
    state.message = action.payload.data.message;
    return state;
  },
  [addTopics.fulfilled]: (state, action) => {
    state.topics = action.payload.data.data.data
    return state;
  },
  [addTopics.rejected]: (state, action) => {
    state.message = action.payload.data.message;
    return state;
  },
  [deleteTopics.fulfilled]: (state, action) => {
    const {
      arg: { topic_id },
    } = action.meta;
    if (topic_id) {
      state.news = state.news.filter((item) => item.id !== topic_id);
    };
  },
  [updateTopics.fulfilled]: (state, action) => {
    const {
      arg: { topic_id },
    } = action.meta;
    if (topic_id ) {
      state.news = state.news.map((item) =>
        item.id === topic_id ? action.payload.data : item
      );
    } else {
      console.warn("No data to update or invalid topic ID.");
    }
  },
  //getComments
 [getcomments.fulfilled]: (state, action) => {
  state.topics = action.payload?.data?.data?.data;
  return state; 
},
  [getcomments.rejected]: (state, action) => {
    state.message = action.payload?.data?.message || "Unknown error";
    return state;
  },
  
  //CardEndpoints
  [cardendpoint.fulfilled]: (state, action) => {
    state.news = action?.payload?.data?.data?.data
    return state;
  },
  [cardendpoint.rejected]: (state, action) => {
    state.message = action.payload.data.message;
    return state;
  },
  
  //deleteComments
  [deletecomments.fulfilled]: (state, action) => {
    const { comment_id } = action.meta.arg;
    if (comment_id) {
      state.comments = state.comments.filter((comment) => comment.id !== comment_id);
    }
  },
    //deleteStories
    [deletestories.fulfilled]: (state, action) => {
      const { stories_id } = action.meta.arg;
      if (stories_id) {
        state.newsStories = state.newsStories.filter((story) => story.id !== stories_id);
      }
    },
  [deletecomments.rejected]: (state, action) => {
    state.message = action.payload.data.message;
    return state;
  },
  [getnewsad.fulfilled]: (state, action) => {
    state.newsAd = action.payload.data.data.data
    return state;
  },
  [getcity.fulfilled]: (state, action) => {
    state.cities = action.payload.data.data.data
    return state;
  },
  [getcity.rejected]: (state, action) => {
    state.message = action.payload.data.message;
    return state;
  },
  [getstates.fulfilled]: (state, action) => {
    state.states = action.payload.data.data.data
    return state;
  },
  [getstates.rejected]: (state, action) => {
    state.message = action.payload.data.message;
    return state;
  },
  [CardPreview.fulfilled]: (state, action) => {
    state.CardPreview = action.payload.data.data.data
    return state;
  },
  [CardPreview.rejected]: (state, action) => {
    state.message = action.payload.data.message;
    return state;
  },
    [getAdminStats.fulfilled]: (state, action) => {
    state.states = action.payload.data.data.data
    return state;
  },
    [getAdminStats.rejected]: (state, action) => {
    state.message = action.payload.data.message;
    return state;
  },
  },
});

const { reducer } = newsSlice;
export default reducer;