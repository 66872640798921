import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Autocomplete, Button, Card, Container, Grid, TextField, Typography } from '@mui/material';
import MediaCard from '../card/Card';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getAdminStats ,getcategories } from '../../slices/news';
import { useDispatch, useSelector } from 'react-redux';
import StatusDisplay from './statsDisplay';
import News from '../../pages/news/News';
import { setStatus } from '../../slices/Status';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Loader from '../loader/Loader';
import loaderFile from "../../assets/gif/loader.gif"

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({ setQuery,setPage,newsData, setNewstype, setLoader, setCity, city, cityData, setCategory, category,startDate,setStartDate,endDate,setEndDate,newsSourceName,setNewsSourceName,bottomLoader }) {
  const [value, setValue] = React.useState(0);
  const [searchParams] = useSearchParams();
  const [stats, setstats] = React.useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const status = useSelector((state) => state.status);
  const {categories: catData} = useSelector((state) => state.news);

  // const [startDate, setStartDate] = React.useState(null); 
  // const [endDate, setEndDate] = React.useState(null); 

//  console.log("newsData",newsData);

 React.useEffect(() => {
  dispatch(getcategories()) 
   dispatch(getAdminStats())
    .then((data) => {
      const updata = data.payload.data.data
      setstats(updata);
    })
      .catch(() => {
        setLoader(false);
      })
},[])

  // Extract the news type from the search parameters
  React.useEffect(() => {
    const searchQuery = searchParams.get('tab');
    
    if (searchQuery === 'short') {
      setValue(0);
      setNewstype("short");
    } else if (searchQuery === 'web') {
      setValue(1);
      setNewstype("web");
    }
    
  }, [searchParams, setNewstype]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setNewstype("short");
      navigate('?tab=short'); 
    } else if (newValue === 1) {
      setNewstype("web");
      navigate('?tab=web'); 
    }
  };
  const handleEdit = (newsItem) => {
    if (newsItem.type === 'short') {
      setValue(0);
      setNewstype("short");
      navigate('?tab=short'); 
    } else if (newsItem.type === 'web') {
      setValue(1);
      setNewstype("web");
      navigate('?tab=web'); 
    }
  };


//   React.useEffect(() => {
//     const StatusParams = searchParams.get('status');
//     console.log("StatusParams:",StatusParams);
    
//     switch (StatusParams) {
//         case "approved":
//             dispatch(setStatus("approved"));
//             break;
//         case "rejected":
//             dispatch(setStatus("rejected"));
//             break;
//         case "pending":
//             dispatch(setStatus("pending"));
//             break;
//         default:
//             dispatch(setStatus("pending"));
//     }
// }, [dispatch, searchParams]);

React.useEffect(()=>{
  const newsStatus= localStorage.getItem("newsStatus");
  if(newsStatus){
    dispatch(setStatus(newsStatus));
  }
},[])
  const handleStatus = (value) => {
   
    dispatch(setStatus(value));
    // console.log("Val:",value);

    localStorage.setItem("newsStatus",value);
   
  }   
  

  return (
    <Box sx={{ width: '100%' }}>
      <Box>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Short News" {...a11yProps(0)}  sx={{ textTransform: 'initial', minWidth: 0, fontWeight: 'bold' }} />
          <Tab label="Web News" {...a11yProps(1)}  sx={{ textTransform: 'initial', minWidth: 0, fontWeight: 'bold' }} />
        </Tabs>
      </Box>
      
      <CustomTabPanel value={value} index={0}>
        {/* <Box sx={{ display: 'flex',justifyContent:"space-between" ,pt:2,pb:2,alignItems:"center"}}> */}
          <Box sx={{ display: 'flex',gap: 1, mt: 2 }}>
          <Button variant={status === 'pending' ? 'contained' : 'outlined'} onClick={() => handleStatus("pending")} sx={{ borderRadius: 10 }}>Pending</Button>
          <Button variant={status === 'approved' ? 'contained' : 'outlined'} onClick={() => handleStatus("approved")} sx={{ borderRadius: 10 }}>Approved</Button>
          <Button variant={status === 'rejected' ? 'contained' : 'outlined'} onClick={() => handleStatus("rejected")} sx={{ borderRadius: 10 }}>Rejected</Button>
          </Box> 
          {/* {
            stats ? <StatusDisplay status={stats.status} /> : <></>
          } */}

        {/* </Box> */}
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2, marginTop: 2 }}>
          {/* Date Filters */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{ display: 'flex', gap: '20px', width: '50%' }}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
          </LocalizationProvider>
        
          {/* Source Name Filter */}
          <Autocomplete
            sx={{ width: '20%' }}
            fullWidth
            id="news-source-name"
            options={['pti','NewsIT',"slack"]} 
            value={newsSourceName}
            onChange={(e, newValue) =>{
               setNewsSourceName(newValue || '')
               setPage(1)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Source Name"
                variant="outlined"
                InputLabelProps={{ required: true }}
              />
            )}
          />
        
          <Box sx={{ display: 'flex', gap: '20px', width: '50%' ,marginTop:2.5}}>
            {/* Category Filters */}
            <Autocomplete
              sx={{ width: '50%' }}
              options={catData}
              value={category}
              getOptionLabel={(option) => `${option.name}`}
              renderInput={(params) => <TextField {...params} sx={{ marginBottom: 2 }} label="Select Category" variant="outlined" />}
              onChange={(event, newValue) => {
                setCategory(newValue);
                setCity(null)
                setQuery(newValue?.name)
              }}
            />
            
            {/* City Filters  */}
            <Autocomplete
              sx={{ width: '50%' }}
              options={cityData}
              value={city}
              getOptionLabel={(option) => `${option.name}`}
              renderInput={(params) => <TextField {...params} sx={{ marginBottom: 2.5 }} label="Select City" variant="outlined" />}
              onChange={(event, newValue) => {
                setCity(newValue)
                setCategory(null);
                setQuery(newValue?.name)
              }}
            />
          </Box>
        </Box>

        
        <Grid container spacing={2} sx={{ mt: 0, mb: 5 }}>
          {newsData?.map((val, index) => (
            <MediaCard status={status} setLoader={setLoader} key={index} val={val} onEdit={handleEdit}  />
          ))}
           {
        bottomLoader && <Container sx={{display:'flex',alignItems:'center',justifyContent:'center',width:"100%"}}>
               <img src={loaderFile} alt="my-gif" width={400}/>
        </Container>
      }
        </Grid>

      </CustomTabPanel>
     
      <CustomTabPanel value={value} index={1}>
        <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
          <Button variant={status === 'pending' ? 'contained' : 'outlined'} onClick={() => handleStatus("pending")} sx={{ borderRadius: 10 }}>Pending</Button>
          <Button variant={status === 'approved' ? 'contained' : 'outlined'} onClick={() => handleStatus("approved")} sx={{ borderRadius: 10 }}>Approved</Button>
          <Button variant={status === 'rejected' ? 'contained' : 'outlined'} onClick={() => handleStatus("rejected")} sx={{ borderRadius: 10 }}>Rejected</Button>
        </Box>


        {/* </Box> */}
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2, marginTop: 2 }}>
          {/* Date Filters */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{ display: 'flex', gap: '20px', width: '50%' }}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
          </LocalizationProvider>
        
          {/* Source Name Filter */}
          <Autocomplete
            sx={{ width: '20%' }}
            fullWidth
            id="news-source-name"
            options={['pti','NewsIT',"slack"]} 
            value={newsSourceName}
            onChange={(e, newValue) =>{
               setNewsSourceName(newValue || '')
               setPage(1)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Source Name"
                variant="outlined"
                InputLabelProps={{ required: true }}
              />
            )}
          />
        
          <Box sx={{ display: 'flex', gap: '20px', width: '50%' ,marginTop:2.5}}>
            {/* Category Filters */}
            <Autocomplete
              sx={{ width: '50%' }}
              options={catData}
              value={category}
              getOptionLabel={(option) => `${option.name}`}
              renderInput={(params) => <TextField {...params} sx={{ marginBottom: 2 }} label="Select Category" variant="outlined" />}
              onChange={(event, newValue) => {
                setCity(newValue)
                setCategory(null);
                setQuery(newValue?.name)
              }}
            />
            
            {/* City Filters  */}
            <Autocomplete
              sx={{ width: '50%' }}
              options={cityData}
              value={city}
              getOptionLabel={(option) => `${option.name}`}
              renderInput={(params) => <TextField {...params} sx={{ marginBottom: 2.5 }} label="Select City" variant="outlined" />}
              onChange={(event, newValue) => {
                setCity(newValue)
                setCategory(null);
                setQuery(newValue?.name)
              }}
            />
          </Box>
        </Box>

        <Grid container spacing={2} sx={{ mt: 0, mb: 5 }}>
          {newsData?.map((val, index) => (
            <MediaCard setLoader={setLoader} key={index} val={val}  onEdit={handleEdit}  />
          ))}
          {
        bottomLoader && <Container sx={{display:'flex',alignItems:'center',justifyContent:'center',width:"100%"}}>
               <img src={loaderFile} alt="my-gif" width={400}/>
        </Container>
      }
        </Grid>
      </CustomTabPanel>
      
    
              <CustomTabPanel value={value} index={2}>

        <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
          <Button variant={status === 'pending' ? 'contained' : 'outlined'} onClick={() => handleStatus("pending")} sx={{ borderRadius: 10 }}>Pending</Button>
          <Button variant={status === 'approved' ? 'contained' : 'outlined'} onClick={() => handleStatus("approved")} sx={{ borderRadius: 10 }}>Approved</Button>
          <Button variant={status === 'rejected' ? 'contained' : 'outlined'} onClick={() => handleStatus("rejected")} sx={{ borderRadius: 10 }}>Rejected</Button>
        </Box>
      
        <Grid container spacing={2} sx={{ mt: 0, mb: 5 }}>
          {newsData?.map((val, index) => (
            <MediaCard setLoader={setLoader} key={index} val={val}  onEdit={handleEdit}  />
          ))}
        </Grid>
      </CustomTabPanel>
     
    </Box>
  );
}

