import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Button, Grid, Typography, Modal, IconButton } from '@mui/material';
import { getcomments, deletecomments } from '../../slices/news'; // Redux action
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  const [hoveredComment, setHoveredComment] = React.useState(null); // Store the hovered comment ID
  const [openModalComment, setOpenModalComment] = React.useState(null); // Store the open modal state for a comment
  const [comments, setComments] = React.useState([
    {
      id: '',
      news_id: '',
      news_images:'',
      text: '',
      timestamp: '',
      replies: [
        {
          id: '',
          news_id: '',
          text: '',
          timestamp: '',
          replies: [
            {
              id: '',
              news_id: '',
              text: '',
              timestamp: '',
              replies: [],
            },
          ],
        },
      ],
    },
  ]);
  // console.log("Comments:",comments);

  const dispatch = useDispatch();
  const { comment_id } = useParams(); // Use useParams to get comment_id from the route
  //console.log('comment_id from params:', comment_id);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Hover handling
  const handleMouseEnter = (commentId) => {
    setHoveredComment(commentId);
  };

  const handleMouseClose = () => {
    setHoveredComment(null);
  };

  // Modal handling
  const handleOpenModal = (commentId) => {
    setOpenModalComment(commentId);
  };

  const handleCloseModal = () => {
    setOpenModalComment(null);
  };

  // Delete Comments
  const handleDeleteComments = (comment_id) => {
    dispatch(deletecomments({ comment_id }))
      .unwrap()
      .then((response) => {
        //console.log('Comment deleted successfully:', response);

        // Update local comments state by filtering out the deleted comment
        setComments((prevComments) =>
          prevComments.filter((comment) => comment.id !== comment_id)
        );
      })
      .catch((err) => {
        console.error('Failed to delete comment:', err);
      });
  };

  // getComments
  React.useEffect(() => {
    const formData = {
      page: 1,
    };
    dispatch(getcomments(formData))
      .then((response) => {
        setComments(response?.payload?.data?.data?.data);
      })
      .catch((error) => {
        console.error('Error fetching comments:', error);
      });
  }, []);
  
  
  

  return (
    <Box sx={{ width: '100%' }}>
      <Box>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Newsit App " {...a11yProps(0)} sx={{ textTransform: 'initial', minWidth: 0, fontWeight: 'bold' }} />
          <Tab label="Instagram" {...a11yProps(1)} sx={{ textTransform: 'initial', minWidth: 0, fontWeight: 'bold' }} />
          <Tab label="Facebook" {...a11yProps(2)} sx={{ textTransform: 'initial', minWidth: 0, fontWeight: 'bold' }} />
          <Tab label="Twitter" {...a11yProps(3)} sx={{ textTransform: 'initial', minWidth: 0, fontWeight: 'bold' }} />
        </Tabs>
      </Box>

      {/* First Tab Content */}
      <CustomTabPanel value={value} index={0}>
        <Grid container spacing={3} sx={{ mt: 1, mb: 5 }}>
          {comments.map((comment) => {
            return (
              <Grid item xs={6} key={comment.id}>
                <Box
                  sx={{
                    background: '#e7f3ff',
                    border: '1px solid #77b5f4',
                    borderRadius: 2,
                    padding: 3,
                    position: 'relative',
                  }}
                  onMouseEnter={() => handleMouseEnter(comment.id)}
                  onMouseLeave={handleMouseClose}
                >
                  <Grid container spacing={2} alignItems={'center'}>
                    <Grid item xs={3}>
                      <img
                      src="https://stg-newsit-media1.s3.amazonaws.com/news_card_images/6709536f345942da2ed575eb/ta/6709536f345942da2ed575eb_ta.jpg"
                      width={150}
                        alt="User"
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="h6">{comment.news_id}</Typography>
                      <Typography>{new Date(comment.timestamp).toLocaleString()}</Typography>
                      <Typography>{comment.text}</Typography>
                      <Grid sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => handleDeleteComments(comment.id)}
                        >
                          Delete
                        </Button>
                      </Grid>
                    </Grid>
                    {hoveredComment === comment.id && (
                      <IconButton
                        sx={{ position: 'absolute', top: 8, right: 8 }}
                        onClick={() => handleOpenModal(comment.id)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    )}
                  </Grid>

                  {/* Modal*/}
                  <Modal
                    open={openModalComment === comment.id}
                    onClose={handleCloseModal}
                  >
                    <Box
                      sx={{
                        position: 'absolute', top: '50%', left: '50%',transform: 'translate(-50%, -50%)',bgcolor:'background.paper',borderRadius: '8px',
                        boxShadow: 24, p: 4,maxWidth: '100%', width: '30%',
                      }}
                    >
                      <IconButton
                        sx={{ position: 'absolute', top: 2, right: 0 }}
                        onClick={handleCloseModal}
                      >
                        <CloseIcon  color="primary"/>
                      </IconButton>
                      {/* Content */}
                      <Grid>
                      <img
                        src="https://stg-newsit-media1.s3.amazonaws.com/news_card_images/6709536f345942da2ed575eb/ta/6709536f345942da2ed575eb_ta.jpg"
                        width={'100%'}
                        alt="User"
                        style={{ borderRadius: '8px' }} 
                      />
                    </Grid>
                    </Box>
                  </Modal>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </CustomTabPanel>

      
      <CustomTabPanel value={value} index={1}>
        <Grid container spacing={3} sx={{ mt: 1, mb: 5}}>
        {Array.from(Array(6)).map((val, index) => (
          <Grid item xs={6} key={index}>
            <Box sx={{background:'#e7f3ff', border:'1px solid #77b5f4', borderRadius: 2, padding: 3}}>
              <Grid container spacing={2} alignItems={'center'}>
                  <Grid item xs={3}>
                    <img src='https://img.freepik.com/free-photo/portrait-man-laughing_23-2148859448.jpg?size=338&ext=jpg&ga=GA1.1.2008272138.1728432000&semt=ais_hybrid' width={150}></img>
                  </Grid>
                  <Grid item xs={9}>
                    <Box display={'flex'} justifyContent={'space-between'}>
                      <Typography variant='h6'>User_Id</Typography>
                      <Typography variant='p'>08:01 PM | 25 Feb 2024</Typography>
                    </Box>
                    <Typography variant='span'>Bio</Typography>
                    <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Typography>
                    <Grid item xs={12} sx={{display:'flex',justifyContent:'end' }}>
                    <Button type='submit' sx={{marginTop: 3}} variant='contained' color='error'>Delete</Button>
                    </Grid>
                  </Grid>
              </Grid>
            </Box>
            </Grid>
        ))}
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
      <Grid container spacing={3} sx={{ mt: 1, mb: 5}}>
        {Array.from(Array(6)).map((val, index) => (
          <Grid item xs={6} key={index}>
            <Box sx={{background:'#e7f3ff', border:'1px solid #77b5f4', borderRadius: 2, padding: 3}}>
              <Grid container spacing={2} alignItems={'center'}>
                  <Grid item xs={3}>
                    <img src='https://img.freepik.com/free-photo/portrait-man-laughing_23-2148859448.jpg?size=338&ext=jpg&ga=GA1.1.2008272138.1728432000&semt=ais_hybrid' width={150}></img>
                  </Grid>
                  <Grid item xs={9}>
                    <Box display={'flex'} justifyContent={'space-between'}>
                      <Typography variant='h6'>User_Id</Typography>
                      <Typography variant='p'>08:01 PM | 25 Feb 2024</Typography>
                    </Box>
                    <Typography variant='span'>Bio</Typography>
                    <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Typography>
                    <Grid item xs={12} sx={{display:'flex',justifyContent:'end' }}>
                    <Button type='submit' sx={{marginTop: 3}} variant='contained' color='error'>Delete</Button>
                    </Grid>
                  </Grid>
              </Grid>
            </Box>
            </Grid>
        ))}
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
      <Grid container spacing={3} sx={{ mt: 1, mb: 5}}>
        {Array.from(Array(6)).map((val, index) => (
          <Grid item xs={6} key={index}>
            <Box sx={{background:'#e7f3ff', border:'1px solid #77b5f4', borderRadius: 2, padding: 3}}>
              <Grid container spacing={2} alignItems={'center'}>
                  <Grid item xs={3}>
                    <img src='https://img.freepik.com/free-photo/portrait-man-laughing_23-2148859448.jpg?size=338&ext=jpg&ga=GA1.1.2008272138.1728432000&semt=ais_hybrid' width={150}></img>
                  </Grid>
                  <Grid item xs={9}>
                    <Box display={'flex'} justifyContent={'space-between'}>
                      <Typography variant='h6'>User_Id</Typography>
                      <Typography variant='p'>08:01 PM | 25 Feb 2024</Typography>
                    </Box>
                    <Typography variant='span'>Bio</Typography>
                    <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Typography>
                    <Grid item xs={12} sx={{display:'flex',justifyContent:'end' }}>
                    <Button type='submit' sx={{marginTop: 3}} variant='contained' color='error'>Delete</Button>
                    </Grid>
                  </Grid>
              </Grid>
            </Box>
            </Grid>
        ))}
        </Grid>
      </CustomTabPanel>
    </Box>
  );
}
