import React, { useEffect } from 'react';
import { Box, Grid, Typography, Card, Container } from '@mui/material';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';              //WebNews
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';                //ShortNews
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';//Approved
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';   //Pending
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';                   //Rejected
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';   // Business
import MovieOutlinedIcon from '@mui/icons-material/MovieOutlined';                     // Entertainment
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';                 // Science
import MemoryOutlinedIcon from '@mui/icons-material/MemoryOutlined';                   // Technology
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined'; // Religious
import SpaOutlinedIcon from '@mui/icons-material/SpaOutlined';                         // Lifestyle
import FlightOutlinedIcon from '@mui/icons-material/FlightOutlined';                   // Travel
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined';                     // Politics
import SportsSoccerOutlinedIcon from '@mui/icons-material/SportsSoccerOutlined';       // Sports
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';   // Economics
import { useDispatch } from 'react-redux';
import { getAdminStats } from '../../slices/news';
import newsService from '../../services/news.service';

const news = [
  <LanguageOutlinedIcon sx={{ fontSize: 30, color: '#0b4c7c' }} />,
  <ArticleOutlinedIcon sx={{ fontSize: 30, color: '#0b4c7c' }} />, 
];

const status = [
  <CheckCircleOutlineOutlinedIcon sx={{ fontSize: 30, color: '#4caf50' }} />,
  <HourglassEmptyOutlinedIcon sx={{ fontSize: 30, color: '#ff9800' }} />,
  <CancelOutlinedIcon sx={{ fontSize: 30, color: '#f44336' }} /> 
]

// const categories = [
//   <BusinessCenterOutlinedIcon sx={{ fontSize: 30, color: '#0b4c7c' }} />,
//   <MovieOutlinedIcon sx={{ fontSize: 30, color: '#e91e63' }} /> ,
//   <ScienceOutlinedIcon sx={{ fontSize: 30, color: '#4caf50' }} />,
//   <MemoryOutlinedIcon sx={{ fontSize: 30, color: '#03a9f4' }} />,
//   <VolunteerActivismOutlinedIcon sx={{ fontSize: 30, color: '#9c27b0' }} />,
//   <SpaOutlinedIcon sx={{ fontSize: 30, color: '#ff9800' }} /> ,
//   <FlightOutlinedIcon sx={{ fontSize: 30, color: '#3f51b5' }} />,
//   <GavelOutlinedIcon sx={{ fontSize: 30, color: '#f44336' }} />,
//   <SportsSoccerOutlinedIcon sx={{ fontSize: 30, color: '#4caf50' }} /> ,
//   <MonetizationOnOutlinedIcon sx={{ fontSize: 30, color: '#ffeb3b' }} /> 
// ]

const Dashboard = ({setLoader, show}) => {

  const [stats, setstats] = React.useState();
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(getAdminStats())
    .then((data) => {
      // console.log(data);
      
      const updata = data.payload.data.data
      // console.log("Data:",updata);
      
      setstats(updata);
    })
    .catch(() => {
      setLoader(false);
    })  
  },[])
  // console.log("stats:",stats);
  

  return (
    <Container className='content' sx={{mt: 13}} maxWidth="fluid">
      <Grid container spacing={3} sx={{mb: 6,display:'flex',flexDirection:'column'}}>
      <Typography variant='h5' sx={{ fontWeight: 'bold',marginLeft:'10px' }}>Short News</Typography>
            {stats?.short && (
          <Grid item xs={8} sx={{display:'flex',justifyContent:'space-between'}}>
            <Card className='dashCard dashCard1' sx={{boxShadow:'none', p: 3, border:'1px solid #cacaca'}}>
            <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center',columnGap:'20px'}}>
              <Box>
                <Typography variant='h4' sx={{ fontWeight: 'bold' }}>
                  {stats?.short?.pending}
                </Typography>
                <Typography variant='h6' sx={{ mt: 1 }}>Pending</Typography>
              </Box>
                  <Box sx={{background:'#eef8ff', p: 1.5, borderRadius: 2}}>
                    <HourglassEmptyOutlinedIcon sx={{ fontSize: 30, color: '#ff9800' }} />
                  </Box>
              </Box>
            </Card>

            <Card className='dashCard dashCard1' sx={{boxShadow:'none', p: 3, border:'1px solid #cacaca'}}>
            <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center',columnGap:'20px'}}>
              <Box>
                <Typography variant='h4' sx={{ fontWeight: 'bold' }}>
                  {stats?.short?.approved}
                </Typography>
                <Typography variant='h6' sx={{ mt: 1 }}>Approved</Typography>
              </Box>
                  <Box sx={{background:'#eef8ff', p: 1.5, borderRadius: 2}}>
                    <CheckCircleOutlineOutlinedIcon sx={{ fontSize: 30, color: '#4caf50' }} />
                  </Box>
              </Box>
            </Card>

            <Card className='dashCard dashCard1' sx={{boxShadow:'none', p: 3, border:'1px solid #cacaca'}}>
            <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center',columnGap:'20px'}}>
              <Box>
                <Typography variant='h4' sx={{ fontWeight: 'bold' }}>
                  {stats?.short?.rejected}
                </Typography>
                <Typography variant='h6' sx={{ mt: 1 }}>Rejected</Typography>
              </Box>
                  <Box sx={{background:'#eef8ff', p: 1.5, borderRadius: 2}}>
                    <CancelOutlinedIcon sx={{ fontSize: 30, color: '#f44336' }} />
                  </Box>
              </Box>
            </Card>
          </Grid>
              )} 
      </Grid>

      <Grid container spacing={3} sx={{mb: 6,display:'flex',flexDirection:'column'}}>
      <Typography variant='h5' sx={{ fontWeight: 'bold',marginLeft:'10px' }}>Web News</Typography>
            {stats?.web && (
          <Grid item xs={8} sx={{display:'flex',justifyContent:'space-between'}}>
            <Card className='dashCard dashCard1' sx={{boxShadow:'none', p: 3, border:'1px solid #cacaca'}}>
            <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center',columnGap:'20px'}}>
              <Box>
                <Typography variant='h4' sx={{ fontWeight: 'bold' }}>
                  {stats?.web?.pending}
                </Typography>
                <Typography variant='h6' sx={{ mt: 1 }}>Pending</Typography>
              </Box>
                  <Box sx={{background:'#eef8ff', p: 1.5, borderRadius: 2}}>
                    <HourglassEmptyOutlinedIcon sx={{ fontSize: 30, color: '#ff9800' }} />
                  </Box>
              </Box>
            </Card>

            <Card className='dashCard dashCard1' sx={{boxShadow:'none', p: 3, border:'1px solid #cacaca'}}>
            <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center',columnGap:'20px'}}>
              <Box>
                <Typography variant='h4' sx={{ fontWeight: 'bold' }}>
                  {stats?.web?.approved}
                </Typography>
                <Typography variant='h6' sx={{ mt: 1 }}>Approved</Typography>
              </Box>
                  <Box sx={{background:'#eef8ff', p: 1.5, borderRadius: 2}}>
                   <CheckCircleOutlineOutlinedIcon sx={{ fontSize: 30, color: '#4caf50' }} />
                  </Box>
              </Box>
            </Card>

            <Card className='dashCard dashCard1' sx={{boxShadow:'none', p: 3, border:'1px solid #cacaca'}}>
            <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center',columnGap:'20px'}}>
              <Box>
                <Typography variant='h4' sx={{ fontWeight: 'bold' }}>
                  {stats?.web?.rejected}
                </Typography>
                <Typography variant='h6' sx={{ mt: 1 }}>Rejected</Typography>
              </Box>
                  <Box sx={{background:'#eef8ff', p: 1.5, borderRadius: 2}}>
                    <CancelOutlinedIcon sx={{ fontSize: 30, color: '#f44336' }} /> 
                  </Box>
              </Box>
            </Card>
          </Grid>
              )} 
      </Grid>

    



    </Container>
    // <>Under construction</>
  );
}

export default Dashboard;