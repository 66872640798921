import { Box, Button, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import React from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {deleteTopics} from '../../slices/news'
import { useDispatch } from 'react-redux';

const TopicsCard = ({ topic, setLoader = () => {},onTopicDelete,onEdit })  => {
 const [subshow, setSubshow] = React.useState(false);
 const dispatch = useDispatch();
//  console.log("topic:",topic);
//  console.log("loader:",loader);
 
 const handleOption = () =>{
     setSubshow(!subshow);
   } 
 const handleDeleteTopic = (topic_id) => {
    if(topic_id) {
    setLoader(true);
    dispatch(deleteTopics({topic_id}))
    .then(() => {
        setLoader(false);
        onTopicDelete(topic_id); 
      })
      .catch(() => {
        setLoader(false); 
      });
    }
 }
  return (
    <>
    <Grid item xs={3} md={3} lg={3}>
        <Card sx={{ maxWidth: '100%', height: '450px', boxShadow:'none', border:'1px solid #cacaca', borderRadius: 3, p: 1 }}>
            <CardContent>
            <Box component="div" sx={{display:'flex',flexDirection:'column',alignItems:'flex-end',}}>
        <Button size='small' sx={{position:'relative', minWidth: 0, padding: 0}} onClick={handleOption}><MoreVertIcon/>
          { subshow && 
          <Box sx={{position:'absolute', bgcolor:'#fff', top: '30px', right: 0, boxShadow:'0px 0px 10px #cacaca', borderRadius: 2, p: 2}}>
          {/* <Link to={`editstories/${val.id}`} style={{textDecoration:'none'}}> */}
                            <Box sx={{ py: 1, px: 1 }}>
                              <div style={{display: "flex", alignItems: "center"}}>
                                <Typography sx={{ color: "#000" }} onClick={() => onEdit(topic)}>Edit</Typography>
                                
                              </div>
                            </Box>
                            
                          {/* </Link> */}
                  <Box sx={{ py: 1, px: 1 }}>
                      <div style={{display: "flex",alignItems: "center",}}>
                        <Typography sx={{ color: "red" }} onClick={()=>handleDeleteTopic(topic?.id)}>Delete</Typography>
                      </div>
                    </Box>
          </Box>
          }
          </Button>
        </Box>
        </CardContent>
        <CardMedia sx={{ height: 250,width:300, borderRadius: 3 }}
        component="img" 
        image={topic?.images?.low_res_url} alt={topic?.multilingual_names?.en}
        title={topic?.multilingual_names?.en}/>
        <CardContent sx={{display:'flex',flexDirection:'column',rowGap:'10px'}}>
        <Typography variant="p" color="text.primary">
        {topic?.multilingual_names?.en}
        </Typography>
        <Typography variant="p" color="text.primary">
         {topic?.multilingual_descriptions?.en?.length > 100
           ? `${topic?.multilingual_descriptions?.en.slice(0, 100)}...`
           : topic?.multilingual_descriptions?.en}
       </Typography>
      </CardContent>
        </Card>
    </Grid>
    </>
  )
}

export default TopicsCard;