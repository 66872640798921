// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes slideIn {
    0% {
        transform: translateX(-50%) scale(0);
        opacity: 0;
    }
    100% {
        transform: translateX(-50%) scale(1);
        opacity: 1;
    }
}

@keyframes slideOut {
    0% {
        transform: translateX(-50%) scale(1);
        opacity: 1;
    }
    100% {
        transform: translateX(-50%) scale(0);
        opacity: 0;
    }
}

.sidebar {
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1111;
    height: 80px;
    background-color: #fff;
    box-shadow: 0px 7px 10px #d4d4d4;
    border-radius: 200px;
    animation: slideIn 0.3s ease-in-out;
}

.sidebar.collapsed {
    animation: slideOut 0.3s ease-in-out;
}

.sidemenu:hover {
    border-radius: 50px;
    background-color: #eaa6b2;
}

.sidemenu {
    margin: 10px;
}

.sidemenu.active svg {
    color: #c00404;
}

.sidemenu.active p {
    color: #c00404;
    font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./src/layouts/sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;IACI;QACI,oCAAoC;QACpC,UAAU;IACd;IACA;QACI,oCAAoC;QACpC,UAAU;IACd;AACJ;;AAEA;IACI;QACI,oCAAoC;QACpC,UAAU;IACd;IACA;QACI,oCAAoC;QACpC,UAAU;IACd;AACJ;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,SAAS;IACT,2BAA2B;IAC3B,aAAa;IACb,YAAY;IACZ,sBAAsB;IACtB,gCAAgC;IAChC,oBAAoB;IACpB,mCAAmC;AACvC;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB","sourcesContent":["@keyframes slideIn {\n    0% {\n        transform: translateX(-50%) scale(0);\n        opacity: 0;\n    }\n    100% {\n        transform: translateX(-50%) scale(1);\n        opacity: 1;\n    }\n}\n\n@keyframes slideOut {\n    0% {\n        transform: translateX(-50%) scale(1);\n        opacity: 1;\n    }\n    100% {\n        transform: translateX(-50%) scale(0);\n        opacity: 0;\n    }\n}\n\n.sidebar {\n    position: fixed;\n    bottom: 30px;\n    left: 50%;\n    transform: translateX(-50%);\n    z-index: 1111;\n    height: 80px;\n    background-color: #fff;\n    box-shadow: 0px 7px 10px #d4d4d4;\n    border-radius: 200px;\n    animation: slideIn 0.3s ease-in-out;\n}\n\n.sidebar.collapsed {\n    animation: slideOut 0.3s ease-in-out;\n}\n\n.sidemenu:hover {\n    border-radius: 50px;\n    background-color: #eaa6b2;\n}\n\n.sidemenu {\n    margin: 10px;\n}\n\n.sidemenu.active svg {\n    color: #c00404;\n}\n\n.sidemenu.active p {\n    color: #c00404;\n    font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
