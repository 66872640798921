import React, { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Container, Pagination } from '@mui/material';
import BasicTabs from '../../components/tabs/Tabs2';
import { getnews, getnewsstories, upnews } from '../../slices/news';

const NewsStories = ({setLoader}) => {

  const [page, setPage] = useState(1);

  const {newsStories: newsData} = useSelector((state) => state.news);

  // const [newsData, setNewsData] = useState([]);

  console.log("NewsData:",newsData);
  

  const dispatch = useDispatch();

  //console.log(newsData);

  useEffect(()=>{
      setLoader(true);
      const formData = {
        "filter": {},  
        "sort": "_id",
        "order": 1,
        page: page,
        "page_size": 12
      }
      if(page === 1){
        dispatch(getnewsstories(formData))
        .then(() => {
          // console.log("Data:",response?.payload?.data?.data?.data);
          // setNewsData(response?.payload?.data?.data?.data);
          
          setLoader(false);
        })
        .catch(()=>{
          setLoader(false);
        })
      }
      else{
        dispatch(upnews(page))
        .then(() => {
          setLoader(false);
        })
        .catch(()=>{
          setLoader(false);
        })
      }
  }, [page])
  
  const updateNews = (event, value) => {
    setPage(value);
  };

  const getTotalPages = () => {
    const totalItems = newsData ? newsData.length : 0;
    console.log("TotalItems:",totalItems);
    return Math.ceil(totalItems / 12);
  };

  // const removeStory = (storyId) => {
  //   setNewsData((prevData) => {
  //     const updatedData = prevData.filter((story) => story.id !== storyId);
  //     return [...updatedData]; // Returning a new array to ensure immutability
  //   });
  // };
  
  return (
    <Container className='content' maxWidth="fluid">
      <BasicTabs newsData={newsData }  setLoader={setLoader} />
        <Link to='/newsstories/addstory'>
        <Button sx={{position:'absolute', top: 100, right: 30}} variant='contained'>New Story</Button>
        </Link>

        {/* Pagination */}
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
        <Pagination
          count={getTotalPages()} 
          page={page}
          onChange={updateNews}
          color="primary"
          shape="rounded"
        />
      </Box>
        {/* <Button variant='contained' onClick={updateNews}>Load More</Button> */}
    </Container>
  )
}

export default NewsStories