import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Pagination, Tab, Tabs, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { addTopics, gettopics, updateTopics, uploadTopicImage } from '../../slices/news';
import { useDispatch, useSelector } from 'react-redux';
import CloudUpload from '../button/Cloudupload';
import TopicsCard from '../card/TopicsCard';
import loaderFile from "../../assets/gif/loader.gif"


const SettingsTab = ({ setLoader, handleApiCallComplete }) => {
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const [topics, setTopics] = useState([]);
  const [fetchedtopics, setFetchedTopics] = useState([]);
  const [popup, setPopup] = useState(false);
  const [bottomLoader, setbottomLoader] = useState(false);

  const [taTopicName, setTaTopicName] = useState('');
  const [enTopicName, setEnTopicName] = useState('');
  const [enTopicDesc, setEnTopicDesc] = useState('');
  const [taTopicDesc, setTaTopicDesc] = useState('');
  const [file, setFile] = useState(null);
  const [page, setPage] = useState(1);

  console.log("topics:", topics);



  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState(null);
  //  console.log("selectedTopic:",selectedTopic);

  const handleChange = (event, newValue) => setValue(newValue);

  const handleOpen = () => setPopup(true);

  const handleClose = () => {
    resetFields();
    setPopup(false);
  };

  const resetFields = () => {
    setTaTopicName('');
    setEnTopicName('');
    setEnTopicDesc('');
    setTaTopicDesc('');
    setFile(null);
    setIsEditMode(false);
  };
  const handleFileChange = (e) => {
    const fileInput = e.target;
    if (fileInput && fileInput.files && fileInput.files[0]) {
      const selectedFile = fileInput.files[0];
      setFile(selectedFile);
      // console.log("File selected:", selectedFile); // Log the selected file
    } else {
      console.error("No file selected or file input is undefined.");
    }
  };



  const fetchTopics = () => {
    const formData = {
      filter: {},
      sort: '_id',
      order: 1,
      page: page,
      page_size: 12,
    };

    setbottomLoader(true);

    dispatch(gettopics(formData))
      .then((data) => {
        const fetchedTopics = data?.payload?.data?.data || [];
        setFetchedTopics(fetchedTopics?.data);
        // Append new topics to the existing list
        setTopics((prevTopics) => [...prevTopics, ...(fetchedTopics?.data || [])]);
        handleApiCallComplete();
      })
      .catch((error) => console.error('Error fetching topics:', error))
      .finally(() => setbottomLoader(false));
  };

  const updateNews = (event, value) => {
    setPage(value);
  };

  const getTotalPages = () => {
    const totalItems = topics ? topics.length : 0;
    console.log("TotalItems:", totalItems);
    return Math.ceil(totalItems / 12);
  };


  const handleAddNewTopic = () => {
    if (taTopicName.trim() || enTopicName.trim()) {
      const topicData = {
        multilingual_descriptions: { en: enTopicDesc, ta: taTopicDesc },
        multilingual_names: { en: enTopicName.trim(), ta: taTopicName.trim() },
      };

      if (isEditMode) {
        dispatch(updateTopics({ topic_id: selectedTopic?.id, formData: topicData }))
          .then(() => {
            // console.log("selectedTopic:",selectedTopic?.id);


            if (file) {
              const formData = new FormData();
              // console.log("formData:",formData);

              formData.append('image', file);

              // Ensure the correct key is passed
              dispatch(uploadTopicImage({ topic_id: selectedTopic?.id, formData: formData }))
                .then((res) => {
                  // console.log("Image uploaded successfully:",res);
                  fetchTopics();
                })
                .catch((error) => console.error('Error uploading image:', error));
            } else {
              console.warn("No file provided for upload");
              fetchTopics();
            }
            resetFields();
            setPopup(false);
          })
          .catch((error) => console.error('Error updating topic:', error));
      } else {
        dispatch(addTopics(topicData))
          .then((response) => {
            // console.log("Response:",response);

            const newTopicId = response?.payload?.data?.data?.data?.id;
            // console.log("newTopicId:",newTopicId);

            if (file) {
              const formData = new FormData();
              // console.log("formData:",formData);

              formData.append('image', file);

              // Ensure the correct key is passed
              dispatch(uploadTopicImage({ topic_id: newTopicId, formData: formData }))
                .then((res) => {
                  // console.log("Image uploaded successfully:",res);
                  fetchTopics();
                })
                .catch((error) => console.error('Error uploading image:', error));
            } else {
              console.warn("No file provided for upload");
              fetchTopics();
            }
            resetFields();
            setPopup(false);
          })
          .catch((error) => console.error('Error creating topic:', error));
      }
    } else {
      alert('Please fill in the topic name!');
    }
  };

  const handleUpdateTopic = (topic) => {
    setIsEditMode(true);
    setSelectedTopic(topic);
    setEnTopicName(topic?.multilingual_names?.en || '');
    setTaTopicName(topic?.multilingual_names?.ta || '');
    setEnTopicDesc(topic?.multilingual_descriptions?.en || '');
    setTaTopicDesc(topic?.multilingual_descriptions?.ta || '');
    setFile(null);
    setPopup(true);
  };

  const handleTopicDelete = (deletedTopicId) => {
    setTopics((prevTopics) => prevTopics.filter((topic) => topic.id !== deletedTopicId));
  };

  useEffect(() => {
    fetchTopics();
  }, [page]);

  const handleScroll = (e) => {
    const bottomReached =
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10;

    // Check if the user has scrolled close to the bottom and if there are more items
    if (bottomReached && !bottomLoader && fetchedtopics?.length !== 0) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <>
      <Box sx={{ width: '98vw', padding: 5 ,marginTop: 2, maxHeight: '85vh', overflow: 'auto' }} 
      onScroll={handleScroll}
      
      >
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{ marginBottom: 3, borderBottom: 1, borderColor: 'divider' }}
        >
          <Tab label="Topic Management" sx={{ textTransform: 'capitalize', fontWeight: 'bold' }} />
          <Tab label="News Management" sx={{ textTransform: 'capitalize', fontWeight: 'bold' }} />
        </Tabs>
        {value === 0 && (
          <Grid container spacing={3}
          
          >
            <Grid item xs={8}>
              <Typography variant="h5" fontWeight="bold">
                Topic Management
              </Typography>
            </Grid>
            <Grid item xs={4} textAlign="end">
              <Button
                variant="contained"
                size="large"
                onClick={handleOpen}
                sx={{ textTransform: 'none' }}
              >
                New Topic
              </Button>
            </Grid>
            <Grid
              container
              spacing={2}
            >
              {topics.map((topic, index) => (
                <TopicsCard
                  key={index}
                  topic={topic}
                  onTopicDelete={handleTopicDelete}
                  onEdit={handleUpdateTopic}
                />
              ))}
            </Grid>
            {bottomLoader && (
              <Container
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <img src={loaderFile} alt="loader" width={400} />
              </Container>
            )}

          </Grid>
        )}

      </Box>

      <Dialog open={popup} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle sx={{ fontWeight: 'bold', padding: 3 }}>
          {isEditMode ? 'Edit Topic' : 'Add New Topic'}
        </DialogTitle>
        <DialogContent sx={{ paddingX: 3, paddingBottom: 3 }}>
          <Box sx={{ display: 'flex', gap: 3, marginBottom: 2 }}>
            <TextField fullWidth margin='normal' label='TA Topic Name' value={taTopicName}
              onChange={(e) => setTaTopicName(e.target.value)}
            />
            <TextField fullWidth margin='normal' label='EN Topic Name' value={enTopicName}
              onChange={(e) => setEnTopicName(e.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', gap: 3, marginBottom: 3 }}>
            <TextField sx={{
              marginBottom: 1,
              '& .MuiInputBase-root': { height: '100px', display: 'flex', alignItems: 'start', overflow: 'hidden', position: 'static', },
              '& .MuiInputBase-input': { overflow: 'auto', height: '100%' },
            }} fullWidth multiline InputProps={{ style: { overflow: "auto" } }}
              margin='normal' label='TA Topic Decription' value={taTopicDesc}
              onChange={(e) => setTaTopicDesc(e.target.value)} />
            <TextField sx={{
              marginBottom: 1,
              '& .MuiInputBase-root': { height: '100px', display: 'flex', alignItems: 'start', overflow: 'hidden', position: 'static', },
              '& .MuiInputBase-input': { overflow: 'auto', height: '100%' },
            }} fullWidth multiline InputProps={{ style: { overflow: "auto" } }}
              margin='normal' label='EN Topic Decription' value={enTopicDesc}
              onChange={(e) => setEnTopicDesc(e.target.value)} />
          </Box>
          <Box
            sx={{
              marginX: 'auto',
              width: '60%',
              textAlign: 'center',
              marginBottom: 3,
            }}
          >
            <Typography sx={{ marginBottom: 1 }}>Image</Typography>
            {isEditMode ? (
              <CloudUpload file={file} setFile={setFile} setLoader={setLoader} imgUrl={selectedTopic?.images?.low_res_url} />
            ) : (
              <CloudUpload file={file} setFile={setFile} setLoader={setLoader} imgUrl={null} />
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: 2 }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            onClick={handleAddNewTopic}
            sx={{ textTransform: 'none' }}
          >
            {isEditMode ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

};

SettingsTab.propTypes = {
  setLoader: PropTypes.func.isRequired,
  handleApiCallComplete: PropTypes.func.isRequired,
};

export default SettingsTab;
