import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  TextField,
  Chip,
  FormControlLabel,
  Radio,
  Container,
  Modal,
  Autocomplete,
  FormGroup,
  InputBase,
} from "@mui/material";
import CloudUpload from "../../components/button/Cloudupload";
import { Grid } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Editor from "./Editor";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {  
  createnews,
  createnewsstories,
  updatestories,
  deletenews,
  getcategories,
  getcity,
  getnewsad,
  getnewsbyid,
  getstates,
  getstoriesbyid,
  gettopics,
  updatenews,
  uploadimage,
  uploadstoryimage,
} from "../../slices/news";
import MediaCard from "../../components/card/EditStoryCard";
import UploadImageModel from "../../components/button/UploadImageModel";
import { update } from "lodash";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  p: 2,
  borderRadius: 2,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Search = styled("div")(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: "1px solid #000",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(1, 2),
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#000",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1.5, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "25ch",
    },
  },
}));

//VisuallyInput
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function Editstories({ setLoader,imgUrl }) {
  const [selectedTab, setSelectedTab] = useState(0);
  // const [ImageData, setImageData] = useState(imgUrl);
  const [imagePreview, setImagePreview] = useState(imgUrl);

  const params = useParams();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { categories: catData } = useSelector((state) => state.news);

  const { topics: topData } = useSelector((state) => state.news);

  const { newsAd: adData } = useSelector((state) => state.news);

  const { cities: cityData } = useSelector((state) => state.news);

  const { states: stateData } = useSelector((state) => state.news);

  const [categoryName, setCategoryName] = React.useState(null);

  const [topicsname, setTopicsName] = React.useState(null);

  const [newsid, setNewsid] = useState([]);

  const [tanewsad, settaNewsAd] = React.useState(null);

  const [ennewsad, setenNewsAd] = React.useState(null);

  const [cityname, setCityName] = React.useState(null);

  const [statename, setStateName] = React.useState(null);

  const [file1, setFile1] = useState(null);

  const [file2, setFile2] = useState(null);

  const [inputValue, setInputValue] = useState("");
  const [hashtags, setHashtags] = useState([]);

  const [content1, setContent1] = useState("");

  const [content2, setContent2] = useState("");

  const [newsdata, setNewsdata] = useState({
    category_id: "",
    multilingual_descriptions: {
      en: "News Topic Description",
      ta: "Descripción del tema de noticias",
    },
    multilingual_names: {
      en: "",
      ta: "",
    },
    name: "",
    news: [],
    order: {}
  });

    console.log("newsdata:",newsdata);

  // Function to open and close the modal
  const [opens, setOpens] = React.useState(false);
  const handlesOpen = () => setOpen(true);
  const handlesClose = () => setOpen(false);

  const handleValue = (e, field) => {
    const { value } = e.target;
  
    // Handle updates based on the field name
    setNewsdata(prevData => {
      switch (field) {
        case 'name':
          return {
            ...prevData,
            name: value,
          };
  
        case 'multilingual_names_en':
          return {
            ...prevData,
            multilingual_names: {
              ...prevData.multilingual_names,
              en: value,
            },
          };
       
        case 'multilingual_names_ta':
          return {
            ...prevData,
            multilingual_names: {
              ...prevData.multilingual_names,
              ta: value, 
            },
          };
  
        default:
          return prevData;
      }
    });
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const value = inputValue.trim();
      if (value) {
        setHashtags([...hashtags, value]);
        setInputValue("");
      }
    }
  };

  const handleDeleteChip = (index) => {
    const updatedHashtags = [...hashtags];
    updatedHashtags.splice(index, 1);
    setHashtags(updatedHashtags);
  };

  const handleCheckboxChange = (event) => {
    setNewsdata({
      ...newsdata,
      [event.target.name]: event.target.checked,
    });
  };

  const arrowBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    setLoader(true);
    const formData = {
      filter: {},
      sort: "_id",
      order: 1,
      page: 0,
      page_size: 100,
    };
    dispatch(getcategories(formData))
    // dispatch(gettopics(formData));
    // dispatch(getnewsad(formData));
    // dispatch(getcity());
    // dispatch(getstates());
    dispatch(getstoriesbyid(params.storyid))
      .then((data) => {
        const upData = data.payload.newsdata.data;
        // console.log(upData)
        // setImageData(upData?.images?.low_res_url)
        setImagePreview(upData?.images?.low_res_url || null)
        setNewsdata(upData);
        setCategoryName("Politics");
        setNewsid(upData?.news);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }, []);

  // useEffect(() => {
  //   console.log("Initial newsid:", newsid);
  // }, [newsid]);


  

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!categoryName) {
      alert("Please select a category before submitting.");
      return;
    }
    console.log(newsdata.news)
    const news_ids = newsdata.news.map((news) => news?.id)
    const formData = { ...newsdata, news_ids,order: newsdata.order,};


    setLoader(true);
    // const formData ={ ...newsdata, }
    // console.log("formData:",formData);
    
  
    try {
      // Step 1: Update the story
      const response = await dispatch(
        updatestories({ id: params.storyid,  formData:formData})
      );
  
      console.log("Update response:", response);
      console.log("API Response Payload:", response?.payload);

  
      const updatedNewsData = response?.payload?.newsdata?.data || {};
  

        setNewsdata({
          ...updatedNewsData,
          news: updatedNewsData.news?.length ? updatedNewsData?.news : newsid, // Reassign newsid if it's not affected
        });
  
      // Step 2: Upload image if it exists
      if (file1) {
        const formVata = new FormData();
        formVata.append("image", file1);
        formVata.append("news_id", updatedNewsData?.id);
  
        try {
          await dispatch(uploadstoryimage({ news_id: updatedNewsData?.id, formVata }));
          console.log("Image uploaded successfully.");
        } catch (uploadError) {
          console.error("Image upload error:", uploadError);
          alert("Image upload failed. Please try again.");
        }
      }
    } catch (updateError) {
      console.error("Error updating story:", updateError);
      alert("An error occurred while updating the story.");
    } finally {
      setLoader(false);
      navigate("/newsstories");
    }
    console.log("After Submit - newsid:", newsid);
  };
  
  
  
  

  // Handle file input change
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile1(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setImagePreview(reader.result);
      reader.readAsDataURL(file);
    }
  };
  // const handelChangeCategory = (category) => {
  //   setCategoryName(category);
  //   setNewsdata((prev) => ({ ...prev, category_id: category.id }));
  // };

  return (
    <Container maxWidth="fluid" className="content">
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}>
        <Grid container spacing={2} sx={{ paddingBottom: 2 }}>
          <Grid
            item
            xs={8}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}>
            <ArrowBackIcon onClick={arrowBack} sx={{ cursor: "pointer" }} />
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Edit Stories
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "end" }}>
            <Button
              type="submit"
              size="large"
              sx={{ marginTop: 3 }}
              variant="contained">
              Update Story
            </Button>
          </Grid>
          <Grid
            item
            xs={9}
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}>
                {/* <Autocomplete
      options={catData}
      value={categoryName} 
      getOptionLabel={(option) => `${option.name}`}
      renderInput={(params) => <TextField {...params} sx={{marginBottom: 2}} label="Select Category" variant="outlined" />}
      onChange={(event, newValue) => {
        setCategoryName(newValue);
      }}
    /> */}
            {/* Politics Field */}
            <TextField value="Politics" label="Select Category" variant="outlined" sx={{ marginBottom: 2 }}/>
            <TextField
              sx={{ marginBottom: 2, width: "100%" }}
              fullWidth
              id="outlined-basic"
              label="Topic"
              variant="outlined"
              InputLabelProps={{ required: true }}
              value={newsdata?.name}
              onChange={(e) => handleValue(e, "name")}
            />
            <Grid
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}>
              <Grid className="wrapper" sx={{ width: "50%" }}>
                <Grid item xs={9}>
                  <TextField
                    sx={{ marginBottom: 2, width: "100%" }}
                    fullWidth
                    id="outlined-basic"
                    label="EN Name"
                    variant="outlined"
                    InputLabelProps={{ required: true }}
                    value={newsdata?.multilingual_names?.en}
                    onChange={(e) => handleValue(e, "multilingual_names_en")}
                  /> 
                </Grid>
                <Box
                  sx={{
                    height: "300px",
                    width: "55%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "2px solid #ccc",
                    borderRadius: "8px",
                    overflow: "hidden",
                    padding: "16px",
                  }}>
           <Button
              variant="outlined"
              startIcon={<CloudUploadIcon />}
              component="label"
              fullWidth
               size="large"
              sx={{ width: '100%', py: 2 }}
            >
              {imagePreview ? (
                <img src={imagePreview} alt="Preview" width={100}/>
              ) : (
                "Upload Image"
              )}
              <input type="file" accept="image/*" hidden onChange={handleFileChange} />
            </Button>


                  {open == 1 && (
                    <UploadImageModel
                      handleFileChange={handleFileChange}
                      open={1}
                      handleClose={handleClose}
                      index={1}
                    />
                  )}
                </Box>
              </Grid>
              <Grid sx={{ width: "50%" }}>
                <Grid item xs={9}>
                  <TextField
                    sx={{ marginBottom: 2, width: "100%" }}
                    fullWidth
                    id="outlined-basic"
                    label="TA Name"
                    variant="outlined"
                    InputLabelProps={{ required: true }}
                    value={newsdata?.multilingual_names?.ta}
                    onChange={(e) => handleValue(e, "multilingual_names_ta")}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            {newsid.map((val, index) => (
              <MediaCard setLoader={setLoader} key={index} val={val} newsdata={newsdata} setNewsdata={setNewsdata}/>
            ))}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
