import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box, Grid, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { updateaction,deletestories, updatestories } from '../../slices/news';
import { useDispatch } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';

export default function MediaCard({ val, setLoader, newsdata, setNewsdata }) {

  const [subshow, setSubshow] = React.useState(false);
  // console.log("val:",newsdata.order);
  // console.log("newsdata:",newsdata);
  

  const dispatch = useDispatch();

  const date = new Date(val?.created_at);

// Get the formatted date
const formattedDate = date.toLocaleDateString('en-GB', {
  day: '2-digit',
  month: 'short',
  year: 'numeric'
});

// Get the formatted time
const formattedTime = date.toLocaleTimeString('en-GB', {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false
});

const handleOption = () =>{
  setSubshow(!subshow);
}

const styles = {
  textContainer: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

const handleDelete = ( val)=>{
  const updatedNewsIds = newsdata?.news?.filter((news) => val?.id !== news.id)?.map((news) => news.id);
  const updatedOrder = { ...newsdata?.order };
  delete updatedOrder[val?.id];
  let updatedStoryData = {
    ...newsdata, news_ids: updatedNewsIds, order: updatedOrder, 
  }
   delete updatedStoryData.news;   
setLoader(true);
  dispatch(updatestories({ id: newsdata?.id, formData:updatedStoryData }))
.then((response) => {
 //console.log(response);
 setLoader(false);
window.location.reload();
})
.catch(()=>{
  setLoader(false);
})
}

  const handleOrderChange = (e, id) => {
    const updatedValue = e.target.value;
    const updatedOrder = { ...newsdata?.order, [id]: updatedValue };
    setNewsdata({
        ...newsdata,
        order: updatedOrder
    });
  };
  
  

// const handleReject=(news_id)=>{
// setLoader(true);
// const action = "reject";
// dispatch(updateaction({news_id, action}))
// .then(() => {
//   setLoader(false);
//   window.location.reload();
// })
// .catch(()=>{
//   setLoader(false);
// })
// }

  return (
    <Grid item xs={3} md={3} lg={3}>
    <Card sx={{ maxWidth: '100%', height: '330px', boxShadow:'none', border:'1px solid #cacaca', borderRadius: 3, p: 1 }}>
    <CardContent>
        <Box component="div" sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
          <TextField value={newsdata?.order[val?.id] || ''} onChange={(e) => handleOrderChange(e, val?.id)}  variant='outlined' size='small' fullWidth={false} 
          inputProps={{style:{ fontSize: '14px',  padding: '8px', backgroundColor: '#f5f5f5',borderRadius: '4px', borderColor: '#1976d2',color: '#1976d2',}}}
          sx={{ width: '70px', '& .MuiInputBase-root': {borderColor: '#1976d2',  borderRadius: '4px',fontWeight: '500',textAlign: 'center', },'& .MuiOutlinedInput-root.Mui-focused': {borderColor: '#1976d2',boxShadow: '0 0 10px rgba(25, 118, 210, 0.2)', }}}
          label='Order *'  type="number"  />
            <Button size='small' sx={{ position: 'relative', minWidth: 0, padding: 0 }} onClick={() => handleDelete(val)}>
              <DeleteIcon  />
          </Button>
        </Box>
        {/* <Typography style={styles.textContainer} variant="p" color="text.secondary" sx={{fontSize: 16, mt: 1}}>
        {val?.content?.en?.headlines}
        </Typography> */}
      </CardContent>
      <CardMedia
        sx={{ height: 250, mx: 2, borderRadius: 3 }}
        image={val?.news_card_images?.en?.low_res_url || val?.images?.low_res_url}
        title="green iguana"
      />
    </Card>
    </Grid>
  );
}
